import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Art of Noise - The Seduction of Claude Debussy",
  "date": "1999-06-28"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Art Of Noise - The Seduction of Claude Debussy`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`A prerecorded MD, from ZTT records. Filed under date of original release.`}</p>
    <p>{`The track titles on the disc are all caps, I have listed them here as mixed case.`}</p>
    <p>{`This is also referenced as part of the ZTT discography
`}<a parentName="p" {...{
        "href": "https://www.zttaat.com/release.php?item=465"
      }}>{`here`}</a></p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol">{`Il Pleure (At the Turn of the Century)`}</li>
      <li parentName="ol">{`Born on a Sunday`}</li>
      <li parentName="ol">{`Dreaming in Colour`}</li>
      <li parentName="ol">{`On Being Blue`}</li>
      <li parentName="ol">{`Continued in Colour`}</li>
      <li parentName="ol">{`Rapt: In the Evening Air`}</li>
      <li parentName="ol">{`Metaforce`}</li>
      <li parentName="ol">{`The Holy Egoism of Genius`}</li>
      <li parentName="ol">{`La flûte de Pan`}</li>
      <li parentName="ol">{`Metaphor on the Floor`}</li>
      <li parentName="ol">{`Approximate Mood Swing No:2`}</li>
      <li parentName="ol">{`Pause`}</li>
      <li parentName="ol">{`Out of This World (Version 138)`}</li>
    </ol>
    <h2>{`Disc Notes`}</h2>
    <ul>
      <li parentName="ul">{`All titles on the disc are uppercased and mushed into basic ASCII.`}</li>
      <li parentName="ul">{`track 7 is `}<em parentName="li">{`excellent`}</em>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      